import React from "react";
import { Button, Container, Grid, Option, Select, Typography } from "@mui/joy";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import { useDispatch,useSelector } from "react-redux";
import {setTitle,setBody,setBatch,pushNotificationState,postNotification} from "../../reducers/pushNotificationSlice"

export default function PushNotification() {
  const dispatch = useDispatch()
  const {title,body,batch} = useSelector(pushNotificationState)
  return (
    <Container>
      <Typography level="h5" mb={3}>
        Push Notification
      </Typography>
      <Grid container spacing={2} sx={{ flexGrow: 1 }} component="form">
        <Grid xs={12} md={6}>
          <FormControl id="Id" required size="sm" color="primary">
            <FormLabel>Title</FormLabel>
            <Input
              placeholder="Title"
              name="title"
              type="text"
              autoComplete="on"
              autoFocus
              fullWidth
              value={title}
              variant="outlined"
              onChange= {(e)=> dispatch(setTitle({value:e.target.value}))}
            />
            {/* <FormHelperText>Help!</FormHelperText> */}
          </FormControl>
        </Grid>
        <Grid xs={12} md={6}>
          <FormControl id="Id" required size="sm" color="primary">
            <FormLabel>Description</FormLabel>
            <Input
              placeholder="Description"
              name="description"
              type="text"
              autoComplete="on"
              autoFocus
              fullWidth
              value={body}
              variant="outlined"
              onChange= {(e)=> dispatch(setBody({value:e.target.value}))}
            />
            {/* <FormHelperText>Help!</FormHelperText> */}
          </FormControl>
        </Grid>
        <Grid
          xs={12}
          md={6}
          className="d-flex align-items-center justify-content-center gap-1"
        >
          <FormControl
            // id={`select_${item.id}`}
            required
            size="sm"
            color="primary"
            className="w-100"
          >
            <FormLabel className="d-flex w-100">Choose Batch</FormLabel>
            <Select
              value={batch}
              onChange={(e, newValue) => {
                dispatch(
                  setBatch({value: newValue})
                );
              }}
            >
              <Option value="Batch1">Batch 1</Option>
              <Option value="Batch2">Batch 2</Option>
              <Option value="Batch3">Batch 3</Option>
            </Select>
          </FormControl>
        </Grid>
        <Grid xs={12}>
          <Button size="sm" sx={{ px: 2 }} onClick={()=> dispatch(postNotification({}))}>
            Send
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
}
