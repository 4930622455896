import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
import { httpsCallable } from "firebase/functions";
import { functions } from "../firebase/config";
const initialState = {
  title: "",
  body:"",
  batch:"Batch1",
};

export const postNotification = createAsyncThunk(
  "pushNotification/sendPushNotification",
  async ( {all}, { dispatch, getState, rejectWithValue }) => {
    try {
      const { title, body, batch } = getState().pushNotification;
      const getDoc = httpsCallable(functions, "sendPushNotification");
      const response = await getDoc({ batch, title, body });
      dispatch(clearState({}))
      return response;
    } catch (error) {
      if (error.name === "FirebaseError") {
        console.log(error.code, error.message);
      }
      return rejectWithValue(error);
    }
  }
);

export const pushNotificationSlice = createSlice({
  name: "pushNotification",
  initialState,
  reducers: {
    setTitle: (state, action) => {
      state.title = action.payload.value;
    },
    setBody: (state, action) => {
      state.body = action.payload.value;
    },
    setBatch: (state, action) => {
      state.batch = action.payload.value;
    },
    clearState: (state) => {
      state.title = "";
      state.body = "";
      state.batch="Batch1";
    },
  },
});

export const { setTitle,setBody,setBatch ,clearState} = pushNotificationSlice.actions;
export const pushNotificationState = (state) => state.pushNotification;
export default pushNotificationSlice.reducer;
